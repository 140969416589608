import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";
import MediaQuery from "../../../layout/MediaQuery";
import { HybridLink } from "../../../layout/Footer";

const ExtendedLink = Styled(HybridLink)``;

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 10rem 0 10rem 0;
  
   ${Media.lessThan("small")`
    margin: 5rem 0 10rem 0;
  `}
`;

const ImageA = Styled(Image)`
    max-width: 668px;
    margin: 10rem auto;
`;

const ImageB = Styled(Image)`
    max-width: 90%;
    margin: 10rem auto 5rem auto;
`;

const Buttons = Styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    
    ${Button} {
        font-size: 1.6rem;
        border-radius: 1rem;
        &:first-of-type {
            margin-right: 3rem;
        }
    }
    
    ${Media.lessThan("600px")`
    flex-direction: column;
    
    ${Button} {
        width: calc(100% - 4rem);
        margin: 0 auto;
        &:first-of-type {
            margin-right: auto;
            margin-bottom: 1.5rem;
        }
    }
  `}
`;

const ButtonExtended = Styled(Button)`
  color: white;
`;

const ExtendedDescription = Styled(Description)`
    max-width: ${(props) =>
      props.withMargins ? "calc(100% - 9.5rem)" : "100%"};
    margin: 0 auto 3.6rem auto;
`;

type ResearchMagicProps = {
  headline: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({ headline, imageA, imageB }: ResearchMagicProps) => (
  <Container>
    <Headline>{headline}</Headline>

    <ExtendedDescription withMargins>
      Interested in conducting machine learning research with us?
    </ExtendedDescription>

    <Buttons>
      <ExtendedLink to="/careers/">
        <ButtonExtended type="submit" variant="primarylight" solid>
          See open positions
        </ButtonExtended>
      </ExtendedLink>

      <ExtendedLink to="/blog/machine-learning-internship-at-uizard/">
        <ButtonExtended type="submit" variant="primarylight" solid>
          Uizard Machine Intelligence Semester
        </ButtonExtended>
      </ExtendedLink>
    </Buttons>

    <MediaQuery greaterThan="medium">
      <ImageA
        fluid={"fluid" in imageA ? imageA.fluid : ""}
        alt="Graphics showcasing different technologies used at Uizard: Gradient Descent, Computational Design, Heuristics, Secret Sauce, Language Modeling, Computer Vision, Deep Neural Networks"
      />
    </MediaQuery>

    <MediaQuery lessThan="medium">
      <ImageB
        fluid={"fluid" in imageB ? imageB.fluid : ""}
        alt="Graphics showcasing different technologies used at Uizard: Gradient Descent, Computational Design, Heuristics, Secret Sauce, Language Modeling, Computer Vision, Deep Neural Networks"
      />
    </MediaQuery>
  </Container>
);
