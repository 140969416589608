import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import Content from "../../../layout/Content/Content";

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 16rem 0 5rem 0;
  padding: 0 9rem;
  
  ${Media.lessThan("large")`
    padding: 0 4rem;
  `}
  
  ${Media.lessThan("medium")`
      margin: 11rem 0 5rem 0;
  `}
  
  ${Media.lessThan("small")`
    padding: 0;
    margin: 9rem 0 4rem 0;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
    font-size: 3.6rem;
  
  ${Media.greaterThan("large")`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
  
   ${Media.lessThan("large")`

  `}
`;

const ExtendedDescription = Styled(Description)`
    
`;

type ResearchHeaderProps = {
  headline: string;
};

export default ({ headline }: ResearchHeaderProps) => (
  <Content narrow>
    <Container>
      <ExtendedHeadline as="h1">{headline}</ExtendedHeadline>
      <ExtendedDescription>
        Explore the research and technology that makes Uizard possible. <br />{" "}
        <br />
        The scientific contributions listed on this page showcase some of the
        technology powering Uizard. The presented methods, experiments, and
        results are published for educational purposes only. <br /> <br />
        We share the science, but we keep some secrets. 😉
      </ExtendedDescription>
    </Container>
  </Content>
);
