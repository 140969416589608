import * as React from "react";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import ResearchHeader from "../../components/pages/research/ResearchHeader";
import ResearchMagic from "../../components/pages/research/ResearchMagic";
import ResearchArticles from "../../components/pages/research/ResearchArticles";
import ResearchFeatured from "../../components/pages/research/ResearchFeatured";

const Research = ({ data }) => (
  <Layout type="dark">
    <Meta
      title="Uizard Research | Making Design Magic Happen | Uizard"
      description="Explore the research and technology that makes Uizard possible. We share the science, but we keep some secrets. Read more online now."
      url="/research/"
    />

    <ResearchHeader headline="Uizard Research" />

    <ResearchFeatured />

    <ResearchArticles
      articles={[
        {
          image: "/assets/screenshot-to-design-with-AI.png",
          date: "February 24 2023",
          title:
            "Uizard Screenshot: Convert screenshots to editable mockups with deep neural networks",
          content:
            "What comes to mind when you think of exceptional user experience? If you're a designer or a" +
            "product owner, you probably have an idea of what makes great design…",
          tags: [
            [
              "Blog",
              "https://uizard.io/blog/uizard-screenshot-convert-screenshots-to-mockups/",
            ],
          ],
        },
        {
          image: "/assets/lvp-cv-design.png",
          date: "September 30 2021",
          title:
            "How deep learning is transforming design: NLP and CV applications",
          content:
            "If you have ever tried creating a user interface, you probably quickly realized that design is hard." +
            "Choosing the right colors, using fonts that match, making your layout balanced…",
          tags: [
            [
              "Blog",
              "https://towardsdatascience.com/how-deep-learning-is-transforming-design-cv-and-nlp-applications-4518c50690e6",
            ],
          ],
        },
        {
          image: "/assets/research-ui-layout.png",
          date: "March 27 2021",
          title:
            "Improving UI Layout Understanding with Hierarchical Positional Encodings",
          content:
            "How can we modify transformers for UI-centric tasks? " +
            "Layout Understanding is a sub-field of AI that enables machines to better process the " +
            "semantics and information within layouts such as user interfaces (UIs), text documents, " +
            "forms, presentation slides, graphic design compositions, etc. Companies already invest " +
            "extensive resources into their web/mobile applications' UI and user experience (UX), with " +
            "Fast Company reporting that every $1 spent on UX can return between 2-100x on its investment. ",
          tags: [
            [
              "Blog",
              "https://towardsdatascience.com/improving-ui-layout-understanding-with-hierarchical-positional-encodings-b19e1e9235e",
            ],
          ],
        },
        {
          image: "/assets/research-theme-generator.png",
          date: "February 17 2021",
          title: "Generating Design Systems using Deep Learning",
          content:
            "How do you enable people to easily design beautiful mobile and web apps when they have no design background? " +
            "Easy: you just throw some neural networks at the problem! " +
            "Well, kinda...",
          tags: [
            [
              "Blog",
              "https://tbeltramelli.medium.com/generating-design-systems-using-deep-learning-abe8d1195960",
            ],
          ],
        },
        {
          image: "/assets/research-semi-supervised.jpeg",
          date: "September 4 2020",
          title:
            "From Research to Production with Deep Semi-Supervised Learning",
          content:
            "Semi-Supervised Learning algorithms have shown great potential in training regimes when " +
            "access to labeled data is scarce but access to unlabeled data is plentiful. In this post, we " +
            "discuss how Semi-supervisedlearning approaches can be useful for machine learning production " +
            "environmentsand the lessons we've learned using them at Uizard.",
          tags: [
            [
              "Blog",
              "https://medium.com/@nairvarun18/from-research-to-production-with-deep-semi-supervised-learning-7caaedc39093",
            ],
          ],
        },
        {
          image: "/assets/research-realmix.png",
          date: "December 18 2019",
          title:
            "RealMix: Towards Realistic Semi-Supervised Deep Learning Algorithms",
          content:
            "Semi-Supervised Learning (SSL) algorithms have shown great potential in training regimes" +
            " when access to labeled data is scarce but access to unlabeled data is plentiful. However, our" +
            " experiments illustrate several shortcomings that prior SSL algorithms suffer from. In particular," +
            " poor performance when unlabeled and labeled data distributions differ. To address these observations," +
            " we develop RealMix, which achieves state-of-the-art results on standard benchmark datasets across " +
            "different labeled and unlabeled set sizes while overcoming the aforementioned challenges.",
          tags: [
            ["Paper", "https://arxiv.org/abs/1912.08766"],
            ["GitHub", "https://github.com/uizard-technologies/realmix"],
          ],
        },
        {
          image: "/assets/research-code-2-pix.png",
          date: "June 5 2018",
          title:
            "Code2Pix: Deep Learning Compiler for Graphical User Interfaces",
          content:
            "This work is a collaboration between Uizard and UC Berkeley's Statistics Undergraduate Student " +
            "Association. We provide a deep learning solution to the problem of generating Graphical User Interfaces " +
            "(GUIs) from a textual description. Additionally, intermediary work hints at the possibility of a single " +
            "textual to GUI renderer that works across multiple platforms.",
          tags: [
            [
              "Blog",
              "https://towardsdatascience.com/code2pix-deep-learning-compiler-for-graphical-user-interfaces-1256c346950b",
            ],
            ["GitHub", "https://github.com/ngundotra/code2pix"],
          ],
        },
        {
          image: "/assets/research-teaching-machines.jpg",
          date: "November 30 2017",
          title: "Teaching Machines to Understand User Interfaces",
          content:
            "Semi-Supervised Learning algorithms have shown great potential in training regimes when " +
            "access to labeled data is scarce but access to unlabeled data is plentiful. In this post, we " +
            "discuss how Semi-supervisedlearning approaches can be useful for machine learning production " +
            "environmentsand the lessons we've learned using them at Uizard.",
          tags: [
            [
              "Blog",
              "https://hackernoon.com/teaching-machines-to-understand-user-interfaces-5a0cdeb4d579",
            ],
            ["Demo", "https://www.youtube.com/watch?v=DapKYJ6niCM"],
          ],
        },
        {
          image: "/assets/research-pix-2-code.jpg",
          date: "May 23 2017",
          title:
            "pix2code: Generating Code from a Graphical User Interface Screenshot",
          content:
            "Semi-Supervised Learning algorithms have shown great potential in training regimes when " +
            "access to labeled data is scarce but access to unlabeled data is plentiful. In this post, we " +
            "discuss how Semi-supervisedlearning approaches can be useful for machine learning production " +
            "environmentsand the lessons we've learned using them at Uizard.",
          tags: [
            ["Paper", "https://arxiv.org/abs/1705.07962"],
            ["GitHub", "https://github.com/tonybeltramelli/pix2code"],
            ["Demo", "https://www.youtube.com/watch?v=pqKeXkhFA3I"],
          ],
        },
      ]}
    />

    <ResearchMagic
      headline=""
      imageA={data.magicImageA.childImageSharp}
      imageB={data.magicImageB.childImageSharp}
    />
  </Layout>
);

export default Research;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    magicImageA: file(relativePath: { eq: "research/magic-image@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    magicImageB: file(relativePath: { eq: "research/magic-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
