import * as React from "react";
import { useRef, useState, useEffect, createRef } from "react";
import Media from "@uizard-io/styled-media-query";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";
import Content from "../../../layout/Content/Content";
import BlogCard, { BlogCardContainer } from "../../../cards/BlogCard";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  width: 100%;
  display: flex;
  justify-content:space-between;
`;

const Column = Styled.div`
  max-width: 33.6rem;
  margin: 0  auto;
  ${BlogCardContainer} {
    margin-bottom: 3rem;
  }
  
  ${Media.greaterThan("600px")`
    margin: 0;
    width: calc((100% / 2) - 1.5rem);
  `}
  
  ${Media.greaterThan("medium")`
    width: calc((100% / 3) - 2rem);
  `}
`;

const ExtendedContent = Styled(Content)`
   ${Media.greaterThan("medium")`
        max-width: calc(100% - 10rem);
  `}
`;

const ButtonLoadMore = Styled.button`

  ${Media.lessThan("small")`
        display: none;
  `}
    
   font-size: 1.8rem;
   color: white;
   height: 4.4rem;
   background: ${(props) => props.theme.colors.gray.dark};
   padding: 0 1.5rem;
   margin: 0 auto;
   font-weight: 500;
   border-radius: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   line-height: 2.2rem;
   border: none;
   box-shadow: none;
`;

const ResearchArticles = ({ articles }) => {
  const [articlesColumn1, setArticlesColumn1] = useState([]);
  const [articlesColumn2, setArticlesColumn2] = useState([]);
  const [articlesColumn3, setArticlesColumn3] = useState([]);

  const [articlesColumn1Mob, setArticlesColumn1Mob] = useState([]);
  const [articlesColumn2Mob, setArticlesColumn2Mob] = useState([]);

  const loadArticlesColumn1 = () => {
    const articlesColumn1Initial = [];
    for (let i = 0; i < articles.length; i += 3) {
      articlesColumn1Initial.push(articles[i]);
    }

    setArticlesColumn1(articlesColumn1Initial);
  };

  const loadArticlesColumn1Mob = () => {
    const articlesColumn1MobInitial = [];
    for (let i = 0; i < articles.length; i += 2) {
      articlesColumn1MobInitial.push(articles[i]);
    }

    setArticlesColumn1Mob(articlesColumn1MobInitial);
  };

  const loadArticlesColumn2 = () => {
    const articlesColumn2Initial = [];
    for (let i = 1; i < articles.length; i += 3) {
      articlesColumn2Initial.push(articles[i]);
    }

    setArticlesColumn2(articlesColumn2Initial);
  };

  const loadArticlesColumn2Mob = () => {
    const articlesColumn2MobInitial = [];
    for (let i = 1; i < articles.length; i += 2) {
      articlesColumn2MobInitial.push(articles[i]);
    }

    setArticlesColumn2Mob(articlesColumn2MobInitial);
  };

  const loadArticlesColumn3 = () => {
    const articlesColumn3Initial = [];
    for (let i = 2; i < articles.length; i += 3) {
      articlesColumn3Initial.push(articles[i]);
    }

    setArticlesColumn3(articlesColumn3Initial);
  };

  useEffect(() => {
    loadArticlesColumn1();
    loadArticlesColumn1Mob();
    loadArticlesColumn2();
    loadArticlesColumn2Mob();
    loadArticlesColumn3();
  }, [articles]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      loadArticlesColumn1();
      loadArticlesColumn1Mob();
      loadArticlesColumn2();
      loadArticlesColumn2Mob();
      loadArticlesColumn3();
    });
  }, []);

  return (
    <ExtendedContent narrow>
      <MediaQuery lessThan="600px">
        <Container>
          <Column>
            {articles.map((article, index) => (
              <BlogCard
                key={article.title}
                image={article.image}
                date={article.date}
                title={article.title}
                content={article.content}
                tags={article.tags}
              />
            ))}
          </Column>
        </Container>
      </MediaQuery>

      <MediaQuery greaterThan="600px">
        <MediaQuery lessThan="medium">
          <Container>
            <Column>
              {articlesColumn1Mob.map((article, index) => (
                <BlogCard
                  key={article.title}
                  image={article.image}
                  date={article.date}
                  title={article.title}
                  content={article.content}
                  tags={article.tags}
                />
              ))}
            </Column>
            <Column>
              {articlesColumn2Mob.map((article, index) => (
                <BlogCard
                  key={article.title}
                  image={article.image}
                  date={article.date}
                  title={article.title}
                  content={article.content}
                  tags={article.tags}
                />
              ))}
            </Column>
          </Container>
        </MediaQuery>

        <MediaQuery greaterThan="medium">
          <Container>
            <Column>
              {articlesColumn1.map((article, index) => (
                <BlogCard
                  key={article.title}
                  image={article.image}
                  date={article.date}
                  title={article.title}
                  content={article.content}
                  tags={article.tags}
                />
              ))}
            </Column>
            <Column>
              {articlesColumn2.map((article, index) => (
                <BlogCard
                  key={article.title}
                  image={article.image}
                  date={article.date}
                  title={article.title}
                  content={article.content}
                  tags={article.tags}
                />
              ))}
            </Column>
            <Column>
              {articlesColumn3.map((article, index) => (
                <BlogCard
                  key={article.title}
                  image={article.image}
                  date={article.date}
                  title={article.title}
                  content={article.content}
                  tags={article.tags}
                />
              ))}
            </Column>
          </Container>
        </MediaQuery>
      </MediaQuery>

      <Container>
        <MediaQuery lessThan="small">
          <ButtonLoadMore>Load more</ButtonLoadMore>
        </MediaQuery>
      </Container>
    </ExtendedContent>
  );
};

export default ResearchArticles;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    presentationImageA: file(
      relativePath: { eq: "about/presentation-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1019, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
