import * as React from "react";
import { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Content from "../../../layout/Content/Content";

import logoN from "./logo-nvidia.png";
import logoTWN from "./logo-thenextweb.png";
import logoW from "./logo-wired.png";
import logoFC from "./logo-fastcompany.png";
import logoHN from "./logo-hackernews.png";

const Container = Styled.div`
  position: relative;
  margin: 2rem 0 2rem 0;

  ${Media.greaterThan("medium")`
    margin: 10rem 0 10rem 0;
  `}
`;

const Headline = Styled.div`
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 3rem 0 3rem 0;
  color: #7f7f7f;

  ${Media.greaterThan("medium")`
    font-size: 2rem;
  `}
`;

const Articles = Styled.div`
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${Media.greaterThan("medium")`
    width: 60rem;
  `}

  ${Media.greaterThan("large")`
    width: 100%;
  `}
`;

const Article = Styled.div`
  margin: 0 1.25rem 3rem 1.25rem; 
  text-align: center;

  ${Media.greaterThan("medium")`
    margin: 0 2.5rem 3rem 2.5rem;
  `}
`;

const Link = Styled.a`
  display: inline-block;
  vertical-align: top;
  opacity: 0.5;
  transition: .3s;

  &:hover, &:focus {
    opacity: 1;
  }
`;

const Logo = Styled.img`
  display: block;
  height: 2rem;

  ${Media.greaterThan("medium")`
    height: 2.5rem;
  `}
`;

class ResearchFeatured extends Component {
  state = {
    articles: [
      {
        url: "https://thenextweb.com/apps/2017/05/26/ai-raw-design-turn-source-code/",
        image: logoTWN,
        alt: "The Next Web",
      },
      {
        url: "https://www.wired.co.uk/article/pix2code-ulzard-technologies",
        image: logoW,
        alt: "Wired",
      },
      {
        url: "https://news.ycombinator.com/item?id=14416530",
        image: logoHN,
        alt: "Hacker News",
      },
      {
        url: "https://news.developer.nvidia.com/ai-turns-ui-designs-into-code/",
        image: logoN,
        alt: "Nvidia",
      },
      {
        url: "https://www.fastcodesign.com/90127911/this-startup-uses-machine-learning-to-turn-ui-designs-into-raw-code",
        image: logoFC,
        alt: "Fast Company",
      },
    ],
  };

  render() {
    return (
      <Container>
        <Content>
          <Headline>Our research was featured in</Headline>

          <Articles>
            {this.state.articles.map((article) => (
              <Article key={article.url}>
                <Link href={article.url} target="_blank" rel="noopener">
                  <Logo src={article.image} alt={article.alt} />
                </Link>
              </Article>
            ))}
          </Articles>
        </Content>
      </Container>
    );
  }
}

export default ResearchFeatured;
