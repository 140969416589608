import * as React from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import Styled from "styled-components/macro";
import Description from "../../texts/Description";
import Headline from "../../texts/Headline";

export const BlogCardContainer = styled.div`
  color: white;
  background-color: #181818;
  border-radius: 2rem;
  overflow: hidden;
`;

export const BlogCardContent = styled.div`
  img {
    object-fit: cover;
  }
`;

const BlogCardDetails = styled.div`
  padding: 1rem 2rem 2rem 2rem;
`;

const Date = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  color: #7f7f7f;
  margin: 0 0 1rem 0;
`;

const ImageA = Styled.img`
    width: 100%;
    height: 16rem;
`;

const Title = styled(Headline)`
  font-size: 1.8rem;
  line-height: 2.2rem;
  margin: 0;
  margin-bottom: 1.2rem;
`;

const ExtendedDescription = Styled(Description)`
 font-size: 1.4rem;
 margin: 0;
`;

const BlogCardTags = Styled.div`
 display: flex;
 flex-wrap: wrap;
`;

const Tag = Styled.a`
 margin-top: 2rem;
 font-size: 1.8rem;
 color: white;
 height: 4.4rem;
 background: ${(props) => props.theme.colors.gray.dark};
 padding: 0 1.5rem;
 margin-right: 1.5rem;
 font-weight: 500;
 border-radius: 1rem;
 display: flex;
 align-items: center;
 justify-content: center;
 line-height: 2.2rem;
`;

type BlogCardProps = {
  date: string;
  title: string;
  image: string;
  content: string;
  tags: [string];
};

const BlogCard = forwardRef(
  ({ date, title, content, tags, image }: BlogCardProps, ref) => (
    <BlogCardContainer ref={ref}>
      <BlogCardContent ref={ref}>
        <ImageA src={image} />
        <BlogCardDetails>
          <Date>{date}</Date>
          <Title>{title}</Title>
          <ExtendedDescription>{content}</ExtendedDescription>
          <BlogCardTags>
            {tags.map((tag) => (
              <Tag href={tag[1]} target="_blank">
                {tag[0]}
              </Tag>
            ))}
          </BlogCardTags>
        </BlogCardDetails>
      </BlogCardContent>
    </BlogCardContainer>
  )
);

export default BlogCard;
